const spanish = {
  //Header Home
  ['The ACLU of Pennsylvania is the state’s oldest and largest civil rights organization. We are a non-partisan organization funded exclusively by the generous donations of our supporters. Our mission is to protect, defend and extend the civil rights and civil liberties of all people in Pennsylvania.']:
    'La Unión Americana de Libertades Civiles de Pensilvania (ACLU, por sus siglas en inglés) es la organización de derechos civiles más antigua y grande del estado. Somos una organización no partidista fundada por donaciones generosas de nuestros  colaboradores. Nuestra misión es la de proteger, defender y extender los derechos civiles y libertades civiles de todas las personas.',

  //Header File Form
  ['PLEASE BE ADVISED:']: 'POR FAVOR TENGA EN CUENTA',
  ["The purpose of this intake form is for the ACLU of Pennsylvania ('ACLU-PA') to assess whether it can provide you with assistance or representation. Filling out this form does not create an attorney/client relationship between you and the ACLU-PA. The ACLU-PA does not represent you unless you and the ACLU-PA have both signed a written retainer agreement."]:
    "El propósito de este formulario de ingreso es para que ACLU de Pensilvania (‘ACLU-PA’) determine si puede brindarle asistencia o representación. Completar este formulario no crea una relación abogado/cliente entre usted y ACLU-PA. ACLU-PA no lo representa a menos que usted y ACLU-PA hayan firmado ambos un acuerdo de retención por escrito.",
  ['You are solely responsible']: 'Usted es únicamente responsable',
  ['for any and all statutes of limitations or other deadlines applicable to your specific situation. To protect your rights, please consult with a private attorney to determine deadlines applicable to your case.']:
    'por uno y todos los estatutos de limitaciones u otros plazos aplicables a su situación específica. Para proteger sus derechos, por favor consulte a un abogado privado para determinar los plazos aplicables a su caso.',
  ['Do not wait to hear from the ACLU-PA before proceeding with your matter.']:
    'No espere oír de ACLU-PA antes de proceder con su caso.',
  ['Please be sure to read']: 'Por favor asegúrese de leer',
  ['before filling out this form.']: 'antes de completar este formulario.',
  ['There may be deadlines that could affect your lawsuit or grievance. If you are concerned about whether the time for bringing your complaint is about to pass, you should not rely on filing an ACLU complaint to protect you; you should consult with an attorney of your choice.']:
    'Puede haber plazos de vencimiento que afecten su  demanda o reclamo. Si está preocupado acerca de si la fecha para presentar su queja está por vencer,  no debe confiar en presentar una queja con ACLU para protegerse; usted debe consultar con un abogado de su preferencia.',
  ['If you would like to request legal assistance, fill out the form below.']:
    'Si le gustaría solicitar asistencia legal, complete el formulario de abajo.',

  // Header Form Complete
  ['WE HAVE RECEIVED YOUR COMPLAINT']: 'NOSOTROS HEMOS RECIBIDO SU QUEJA',
  ['Unfortunately, we have very limited resources and we are unable to take most cases. If we are able to help you, we will be back in touch as soon as possible. You should continue to seek other counsel and be mindful that you have a limited period of time to file a lawsuit. Thank you for contacting us, and thank you for your patience.']:
    'Desafortunadamente, tenemos recursos muy limitados y no podemos aceptar la mayoría de los casos. Si podemos ayudarlo, nos volveremos a comunicar lo antes posible. Debe continuar buscando otro abogado y tener en cuenta que tiene un período de tiempo limitado para presentar una demanda. Gracias por contactarnos y gracias por su paciencia.',
  ['Return to ACLU Pennsylvania']: 'Regrese a ACLU Pennsylvania',

  //Misc
  ['Language']: 'Idioma',
  ['Submit Request']: 'Envíe la Solicitud',
  ['Visit ACLU affiliates']: 'Visite los afiliados de ACLU',
  ['Indicates the required fields.']: 'Indica los campos requeridos.',
  ['This field is required.']: 'Este campo es requerido.',
  ['Please review the highlighted fields.']: 'Revise los campos resaltados.',
  ['Select']: 'Seleccionar',
  ['PERMISSION TO COLLECT AND USE INFORMATION']: 'PERMISO PARA RECAUDAR Y UTILIZAR INFORMACIÓN',

  // Titles
  ['ACLU Pennsylvania Online Form To Request Legal Assistance']:
    'Formulario de queja de la ACLU de Pennsylvania',
  ['ACLU PENNSYLVANIA ONLINE FORM TO REQUEST LEGAL ASSISTANCE']:
    'FORMULARIO EN LÍNEA DE ACLU PENNSYLVANIA PARA SOLICITAR ASISTENCIA LEGAL',
  ['REQUEST FOR LEGAL ASSISTANCE']: 'SOLICITUD PARA ASISTENCIA LEGAL',
  ['THE LOCATION OF THIS INCIDENT']: 'EL LUGAR DE ESTE INCIDENTE',
  ['CONTACT INFORMATION']: 'INFORMACIÓN DE CONTACTO',
  ['ONLINE FORM TO REQUEST LEGAL ASSISTANCE']:
    'FORMULARIO EN LÍNEA PARA SOLICITAR ASISTENCIA LEGAL',
  ['MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)']:
    'MI RECLAMO ES EN CONTRA DE LOS SIGUIENTE (OPCIONAL)',
  ['LAWSUIT INFORMATION (OPTIONAL)']: 'INFORMACIÓN DE LA DEMANDA (OPCIONAL)',
  ['DETAILS OF COMPLAINT']: 'DETALLES DE ESTA DEMANDA',
  ['DISCLAIMER AND NOTICE']: 'EXENCIÓN DE RESPONSABILIDAD Y AVISO',
  ['ATTORNEY INFORMATION (OPTIONAL)']: 'INFORMACIÓN DEL ABOGADO (OPCIONAL)',
  ['AGREEMENT']: 'ACUERDO',

  // Incident Location
  ['Did this incident happen in Pennsylvania?']:
    '¿Este incidente ocurrió en Pensilvania?',
  ['Yes']: 'Sí',

  // Contact and Filer Information
  ['Are you contacting us for yourself or on behalf of someone else?']:
    '¿Nos está contactando por usted o por otra persona?',
  ['Are you filing for yourself or on behalf of someone else?']:
    '¿Está presentando para usted o para otra persona?',
  ['Myself']: 'Usted',
  ['Someone else']: 'Otra persona',
  ['The relationship between you and the affected individual']:
    'La relación entre usted y el individuo afectado.',
  ['Contact information of the person affected by the incident']:
    'Información de contacto de la persona afectada en el incidente',
  ["Affected Individual's First Name"]: 'Nombre del individuo afectado',
  ["Affected Individual's Last Name"]: 'Apellido del individuo afectado',
  ['First Name']: 'Primer Nombre',
  ['Last Name']: 'Apellido',
  ['Gender Pronoun']: 'Gender Pronoun',
  ['Do you have a physical / mailing address?']:
    '¿Tiene una dirección física / postal?',
  ['Address line']: 'Dirección',
  ['Address line 2']: 'Dirección, segunda línea',
  ['State']: 'Estado',
  ['City']: 'Ciudad',
  ['Zip Code']: 'Código postal',
  ['Do you have a phone number?']: '¿Tiene un número de teléfono?',
  ['Phone Number']: 'Teléfono',
  ['Mobile Phone Number']: 'Mobile Teléfono',
  ['Do you have an email address?']: '¿Tiene correo electrónico?',
  ['Email']: 'Correo Electrónico',
  ['Title']: 'Título',
  ['Preferred Pronouns']: 'Pronombres de preferencia',
  ['She/Her']: 'Ella',
  ['He/Him']: 'Él',
  ['They/Them']: 'Elle',
  ['Other']: 'Otro',
  ['Miss']: 'Señorita',
  ['Ms.']: 'Señora',
  ['Mr.']: 'Señor',
  ['If a prisoner, prison name?']: 'Nombre de la prisión',
  ['Prisoner Number']: 'La número del prisionero',
  ['Contact Information of the person who is filing on behalf of the affected individual']:
    'Información de contacto de la persona que presenta la demanda en nombre del individuo afectado',
  ['Ethnic Racial Background']: 'Origen étnico / racial',
  ['If we call you back']: "Si le regresamos la llamada, ¿debemos decir que es ACLU quien llama?",

  // Complaint Against
  ['Agency']: 'Agencia',
  ['May we contact this person or agency?']:
    '¿Podemos contactar a esta persona o agencia?',

  // ATTORNEY INFORMATION (OPTIONAL)
  ['Are you represented by a lawyer in this matter?']:
    '¿Está representado por un abogado en este asunto?',
  ['Attorney Full Name']: 'Nombre completo del abogado',
  ['Agency Name']: 'Nombre de la Agencia',

  // Reported
  ['Have you reported this incident to any other agency?']:
    '¿Usted ha denunciado este incidente a alguna otra agencia?',
  ['If yes, please list all the agency names.']: 'Nombre de la agencia',

  // Incident Information
  ['Date of Incident']: 'Fecha del Incidente',
  ['County of Occurrence']: 'Condado del Suceso',
  ['Please describe IN DETAIL, all the events that led you to file this complaint.']:
    'Describa EN DETALLE, en un breve resumen, todos los eventos que le llevaron a presentar esta queja.',
  ['State clearly what you would like the ACLU of Pennsylvania to do for you.']:
    'Por favor incluya la mejor resolución que usted quiere.',
  ['Please provide a brief factual account of the events leading you to file this complaint. Please include dates, places and the names of people directly involved.']:
    'Por favor provea un breve resumen real de los eventos que le llevaron a presentar este reclamo. Por favor incluya fechas, lugares y los nombres de las personas directamente involucradas.',
  ['How have you already tried to solve your issue? Please include information about any response from the person/agency you believe violated your rights.']:
    '¿Ha tratado ya de solucionar este asunto? Por favor incluya información sobre alguna respuesta de la persona/agencia que usted considera violó sus derechos.',
  ['What action would you like the ACLU of Pennsylvania to take? How can we help you resolve your problem? Please include the ultimate resolution you would like to see.']:
    '¿Qué acción le gustaría que ACLU-PA tome? ¿Cómo le podemos ayudar a resolver su problema? Por favor incluya la resolución definitiva que le gustaría obtener.',
  ['Does your complaint relate to one of our cases that you saw in the news?  If so, how?']:
  '¿Su denuncia tiene que ver con alguno de nuestros casos que usted vió en las noticias? Si es así, ¿cómo?',

  // Witness Information
  ['WITNESS INFORMATION (OPTIONAL)']: 'INFORMACIÓN DEL TESTIGO (OPCIONAL)',
  ['May we contact this witness?']: '¿Podemos contactar al testigo?',

  // Lawsuit Information
  ['Please select the date the case was filed.']:
    'Por favor indique la fecha cuando el caso fue presentado',
  ['Firm Name']: 'Nombre de la Firma',
  ['Case Name']: 'Número del Caso',
  ['Case Number']: 'Nombre del Caso',

  // Field Errors
  ['Please enter a valid email address.']:
    'Por favor, introduce una dirección de correo electrónico válida.',
  ['Please enter a valid phone number.']:
    'Por favor ingrese un número de teléfono válido.',
  ['Please enter a valid zip code.']:
    'Por favor ingrese un código postal válido.',

  // Notices
  ['by_checking_this_box']:
    'Al marcar esta casilla,  estoy de acuerdo en permitir que ACLU Pensilvania recaude  y utilice la información que estoy proporcionando para evaluar mi solicitud para asistencia legal.',
  ['location_notice']:
    'Por favor proporciónenos más información acerca del estado donde ocurrió este incidente. Si el incidente ocurrió en otro estado, le dirigiremos a la página donde puede obtener la información de contacto de la filial de ACLU en ese estado. ACLU Pensilvania solo acepta casos que se hayan originado en Pensilvania.',
  ['affiliate_notice']:
    'Si este incidente no ocurrió en el estado de Pensilvania, no podemos ayudarle en este momento. Por favor presione en el botón de abajo para redirigirlo a la filial correcta de ACLU.',
  ['contact_info_notice']:
    'Información de contacto de la persona afectada por el incidente.',
  ['background_notice']:
    'La siguiente información de antecedentes étnicos /raciales es opcional.  Esto se utiliza para fines internos sólo para proporcionar a la ACLU de Pennsylvania información demográfica sobre las comunidades a las que brinda apoyo en todo Pennsylvania.',
  ['complaint_against_notice']:
    'Por favor ingrese la información acerca de la persona, agencia y/u organización contra quien es su demanda.',
  ['filer_contact_info_notice']:
    'Información de contacto de la persona que presenta en nombre de la persona afectada.',
  ['incident_notice']:
    'Brevemente describa el incidente que le llevó a presentar una queja con nuestra oficina. Por favor incluya fechas, lugares y nombres de las personas directamente involucradas. Indique en su resumen si tiene documentación de apoyo y le contactaremos separadamente para obtener copias. Si tiene un enlace con un video relevante, por favor incluya la dirección de URL en el resumen.',
  ['witness_notice']:
    'Si tiene testigos que apoyan su denuncia, por favor ingrese la información aquí.',
  ['attorney_notice']:
    'Si usted está siendo representado por un abogado en este asunto, por favor proporcione la información aquí.',
  ['lawsuit_notice']:
    'Si una demanda criminal o civil ha sido presentada en su contra o de su parte relacionada con su queja, por favor provea la información aquí.',
  ['disclaimer_notice1']:
    'Esta Solicitud no provee asesoría legal, y usted no debe confiar en éste como asesor legal. Usted no debe confiar en la información obtenida de este sitio y debe hablar con un abogado para obtener asesoría sobre su situación específica. No podemos asegurarle tampoco que la información en esta página es completa, precisa o actual.',
  ['disclaimer_notice2']:
    'Esta Solicitud no es un pedido o una oferta por parte de la Unión de Libertades Civiles Americanas y sus filiales para representarlo. No podemos asegurarle que la información que usted proporcione conducirá a tomar una acción específica por parte de la Unión de Libertades Civiles Americanas y sus filiales. Una vez usted complete la Solicitud, la Unión de Libertades Civiles de Pensilvania puede que no haga nada-incluso contactarle-acerca de su situación.',
  ['disclaimer_notice3']:
    '',
  ['disclaimer_notice4']:
    'Mantendremos confidencial su nombre, dirección, número de teléfono y correo electrónico, a menos que usted nos autorice a utilizarlo o en caso de que nos sea ordenado entregarlo por una corte (aunque intentaremos evitar cualquier divulgación).',
  ['agreement_notice1']:
    'Al presionar el botón “Envíe la Solicitud”, usted indica que la información que nos ha proporcionado es verdadera y correcta; que usted entiende que, al aceptar este formulario de demanda,  ACLU de Pensilvania no ha aceptado representarlo; y que usted entiende que ACLU Pensilvania no es responsable de asegurar que cualquier requerimiento del estatuto de limitaciones o plazo límite se ha cumplido en su caso.',
  ['agreement_notice2']:
    'Si usted envía una demanda, usted está de acuerdo en que ACLU Pensilvania o ACLU nacional pueden usar la información que nos ha proporcionado, mientras no incluyamos su nombre, dirección, correo electrónico o número telefónico. Mantendremos confidencial su nombre, dirección, teléfono y correo electrónico a menos que usted nos autorice o que nos sea ordenado a entregarlos por una corte (aunque intentaremos prevenir cualquier revelación).',

  // Checkbox options
  ['Asian']:
    'Asiático',
  ['Black']:
    'Negro, Afroamericano',
  ['Latino or Latina']:
    'Latino or Latinx',
  ['Middle Eastern']:
    'Árabe, del Medio Oriente o África del Norte',
  ['Native American']:
    'Nativo Americano',
  ['Pacific Islander']:
    'Isleño del Pacífico',
  ['White']:
    'Blanco'
}

export default spanish
