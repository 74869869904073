<template>
  <fade-in>
    <div class="header-container">
      <h1>{{ lang[activeLang]['REQUEST FOR LEGAL ASSISTANCE'] }}</h1>

      <p>
        <strong>{{ lang[activeLang]['PLEASE BE ADVISED:'] }} </strong>
        {{
          lang[activeLang][
            'The purpose of this intake form is for the ACLU of Pennsylvania (\'ACLU-PA\') to assess whether it can provide you with assistance or representation. Filling out this form does not create an attorney/client relationship between you and the ACLU-PA. The ACLU-PA does not represent you unless you and the ACLU-PA have both signed a written retainer agreement.'
          ] }}
        <strong class="underlined-strong">{{ lang[activeLang]['You are solely responsible'] }}</strong>
        {{ lang[activeLang]['for any and all statutes of limitations or other deadlines applicable to your specific situation. To protect your rights, please consult with a private attorney to determine deadlines applicable to your case.'] }}
        <strong class="underlined-strong">{{ lang[activeLang]['Do not wait to hear from the ACLU-PA before proceeding with your matter.'] }}</strong>
      </p>
      <p>
        {{ lang[activeLang]['Please be sure to read'] }}
        <span v-if="activeLang === 'English'">
          <a href="https://www.aclupa.org/en/help-resources/file-complaint">about the kinds of cases the ACLU of Pennsylvania accepts</a>
        </span>
        <span v-if="activeLang === 'Español'">
          <a href="https://www.aclupa.org/en/help-resources/file-complaint">acerca de la clase de casos que ACLU-PA acepta</a>
        </span>
        {{ lang[activeLang]['before filling out this form.'] }}
      </p>
      <p>
        {{
          lang[activeLang][
            'There may be deadlines that could affect your lawsuit or grievance. If you are concerned about whether the time for bringing your complaint is about to pass, you should not rely on filing an ACLU complaint to protect you; you should consult with an attorney of your choice.'
          ]
        }}
      </p>
      <p>
        {{
          lang[activeLang][
            "If you would like to request legal assistance, fill out the form below."
          ]
        }}
      </p>
      <p>
        <em>* </em> {{ lang[activeLang]['Indicates the required fields.'] }}
      </p>
    </div>
  </fade-in>
</template>

<script>
import FadeIn from './FadeIn.vue'

export default {
  name: 'FormHeader',
  components: {
    FadeIn
  },
  props: {
    activeLang: {
      type: String,
      default: 'English'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  text-align: left;
}

a {
  text-decoration: none;
  color: #0055aa;
}
.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.spacer {
  width: 2%;
}

.header-logo {
  width: 64px;
  height: 64px;
  color: black;
}

h1 {
  text-align: left;
  font-size: 32px;
}

p {
  text-align: left;
}

.underlined-strong {
  text-decoration: underline;
}

</style>
